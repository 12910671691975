@import "../../../mixins";

.category-section {
  &__wrapper {
    width: 100%;
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    grid-template-rows: 1fr;
    gap: rem(24);

    @include mediaBigDesktop {
      gap: big(24);
    }

    @include mediaDesktop {
      gap: rem(20);
    }

    @include mediaLaptop {
      gap: rem(15);
    }

    @include mediaTablet {
      grid-template-columns: repeat(3, 1fr);
      gap: rem(10);
    }

    @include mediaMobile {
      grid-template-columns: 1fr;
    }
  }

  &:not(.category-section_full) {
    & .category-section__more {
      @include mediaMobile {
        display: flex;
      }
    }

    & .category-card {
      &:nth-child(1n + 9) {
        @include mediaMobile {
          display: none;
        }
      }
    }
  }

  &__more {
    margin-top: rem(20);
    margin-right: auto;
    margin-left: auto;

    display: none;

    @include mediaBigDesktop {
      margin-top: big(20);
    }

    &.button {
      --button-bg-color: var(--bg-light);
      --button-bg-color-hover: var(--accent-secondary-hover);
      --button-gap: #{rem(12)};
      --button-icon-color: var(--text-dark-primary);
      --button-icon-color-hover: var(--text-light-primary);
      --button-min-height: #{rem(40)};
      --button-padding: #{rem(20)};
      --button-text-color: var(--text-dark-primary);
      --button-text-color-hover: var(--text-light-primary);

      @include mediaBigDesktop {
        --button-gap: #{big(12)};
        --button-min-height: #{big(40)};
        --button-padding: #{big(20)};
      }

      & .icon {
        width: rem(14);
        height: rem(14);

        @include mediaBigDesktop {
          width: big(14);
          height: big(14);
        }
      }
    }
  }
}
